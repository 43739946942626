import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,[_c(VCol,[_c('div',{staticClass:"currency-container"},_vm._l((_vm.favoritesCurrencies),function(cur){return _c(VCard,{key:cur.iso,staticClass:"ma-1",class:{'selected': cur===_vm.selectedInList},attrs:{"elevation":_vm.selectedInList === cur ? 4 : 2,"height":"70","width":"70"},on:{"click":function($event){return _vm.onCurrencyClicked(cur)}}},[_c('flag',{attrs:{"iso":cur.flag,"squared":false}}),_c('span',[_vm._v(_vm._s(cur.iso))])],1)}),1)])],1),_c(VRow,[_c(VCol,[_c(VAutocomplete,{staticClass:"v-text-field-hide-details",attrs:{"item-text":_vm.formatCurrency,"items":_vm.exchangeRates,"label":_vm.$t('currencySelector.select'),"outlined":"","return-object":""},model:{value:(_vm.selectedInList),callback:function ($$v) {_vm.selectedInList=$$v},expression:"selectedInList"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }