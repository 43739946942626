import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VContainer,[_c(VRow,[_c(VCol,[_c(VCard,{attrs:{"disabled":_vm.disabled,"color":_vm.selected===1 ? 'primary' : null,"dark":_vm.selected===1,"elevation":_vm.selected===1 ? 6 : 2,"height":"100%"},on:{"click":function($event){return _vm.select(1)}}},[_c(VCardText,[_vm._v(" "+_vm._s(_vm.$t('operationTypeSelector.options.localToForeign', {iso:_vm.config.transactions.baseCurrency.iso.toUpperCase()}))+" ")])],1)],1),_c(VCol,[_c(VCard,{attrs:{"disabled":_vm.disabled,"color":_vm.selected===2 ? 'primary' : null,"dark":_vm.selected===2,"elevation":_vm.selected===2 ? 6 : 2,"height":"100%"},on:{"click":function($event){return _vm.select(2)}}},[_c(VCardText,[_vm._v(" "+_vm._s(_vm.$t('operationTypeSelector.options.foreignToLocal', {iso:_vm.config.transactions.baseCurrency.iso.toUpperCase()}))+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }